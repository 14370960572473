<template>
    <loading :loading="loading" spinner-class="loading-center loading-overlay">
        <b-row no-gutters>
            <b-col sm="12">
                <div class="d-flex justify-content-end">
                    <b-dropdown size="xs" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                           <span>{{ filter.type ? filter.type : 'All files' }}</span>
                        </template>
                        <b-dropdown-item href="#" @click.prevent="filter.type = ''">All files</b-dropdown-item>
                        <b-dropdown-item href="#" @click.prevent="filter.type = 'Images'">Images</b-dropdown-item>
                        <b-dropdown-item href="#" @click.prevent="filter.type = 'Documents'">Documents</b-dropdown-item>
                        <b-dropdown-item href="#" @click.prevent="filter.type = 'Media'">Media</b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="items.length > 0" no-gutters>
            <b-col v-for="(item, index) in items" :key="index" sm="3">
                <transition name="slide-fade" mode="out-in">
                    <div class="file-item">
                        <a href="#"  @click.prevent="openPopup(item)" v-if="item.file_type === 'jpg' || item.file_type === 'png'"><img class="file-item-content" :src="item.file_url"></a>
                        <div v-else class="file-item-content">
                            <div class="p-2">
                                <Icon icon="ci:file-blank-fill" />
                                <span>{{ item.file_name }}</span>
                            </div>
                        </div>
                        <div class="file-item-action">
                            <a :href="item.file_url" target="_blank">
                                <b-button variant="link" size="sm" class="text-secondary mr-2">
                                    <Icon icon="akar-icons:download" class="d-inline" />
                                </b-button>
                            </a>
                            <b-button variant="link" size="sm" class="text-danger" @click="deleteItem(item.id)">
                                <Icon icon="bi:trash" class="d-inline" />
                            </b-button>
                        </div>
                    </div>
                </transition>
            </b-col>
            <b-col sm="12" v-if="!isLastPage">
                <div class="load-more-loading text-center py-4 mt-3">
                    <b-spinner v-if="loadMoreLoading" />
                    <a href="" v-else @click.prevent="loadMore" class="card py-2 px-4 inline">Load More</a>
                </div>
            </b-col>
        </b-row>
        <div v-else class="text-center py-20">
            <empty>
                <p class="pt-2">No file have been added.</p>
            </empty>
        </div>
    </loading>
</template>

<script>
    import { Icon } from '@iconify/vue2'
    import { mapActions, mapState } from 'vuex'
    import Empty from '@/components/Empty.vue'
    export default {
        components: { Icon, Empty },
        computed: mapState('groups', {
            loading: state => state.files.loading,
            items: state => state.files.items,
            filterType () {
                switch (this.filter.type) {
                    case 'Images': return 'image'
                    case 'Documents': return 'document'
                    case 'Media': return 'media'
                    default: return ''
                }
            }
        }),
        watch: {
            filterType () {
                this.loadFiles()
            }
        },
        data () {
            return {
                currentPage: 1,
                perPage: 12,
                loadMoreLoading: false,
                isLastPage: false,
                filter: {
                    type: ''
                }
            }
        },
        methods: {
            ...mapActions('groups', ['getAttachments', 'deleteFile']),
            deleteItem (id) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteFile({ slug: this.$route.params.code, id }).then(() => {
                            this.$swal(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.loadFiles()
                        })
                    }
                })
            },
            openPopup (data) {
                this.$swal({
                    imageUrl: data.file_url,
                    imageWidth: 450,
                    showCancelButton: false,
                    title: data.file_name,
                    confirmButtonText: 'Close'
                })
            },
            loadFiles () {
                this.getAttachments({ slug: this.$route.params.code, limit: this.perPage, type: this.filterType })
                    .then(res => {
                        if (res.currentPage === res.lastPage) {
                            this.isLastPage = true
                        }
                    })
            },
            loadMore () {
                this.loadMoreLoading = true
                this.getAttachments({ slug: this.$route.params.code, limit: this.perPage, page: this.currentPage + 1, append: true, type: this.filterType })
                    .then((res) => {
                        this.currentPage = this.currentPage + 1
                        if (res.currentPage === res.lastPage) {
                            this.isLastPage = true
                        }
                    })
                    .finally(() => {
                        this.loadMoreLoading = false
                    })
            }
        },
        created () {
            this.loadFiles()
        }
    }
</script>
